var site = site || {};
var prodcat = prodcat || {};

(function ($) {
  'use strict';

  Drupal.behaviors.productGrid = {
    attach: function (context) {
      var self = this;
      var bps = Unison.fetch.all();
      var ipadWidth = parseInt(bps.portrait, 10);
      var desktopWidth = parseInt(bps.landscape, 10);
      var $grids = $('.product-grid', context);
      var $carouselGrids = $grids.filter('.product-grid--carousel');
      var $carousels = $('.js-product-carousel', $carouselGrids);
      var hasQuickshop = $grids.hasClass('product-grid--quickshop');
      var $wrapper = $('.product-grid-wrapper', context);
      var is_mobile = $('body').hasClass('device-mobile');
      var desktopSlideCountShaded = 5;
      var mobileSlideCountShaded = 7;
      var desktopSlideCountMultiShaded = 4;
      var mobileSlideCountMultiShaded = 6;
      var toutsPlaceholder = [];

      // Loop through and init the carousels.
      // Carousels might contain variations, so dynamically change the settings before constructing slick
      // @setup - new brand to adjust responsive/dots settings per designs
      $carousels.each(function () {
        var $arrowsDiv = $(this).siblings('.carousel-controls');
        var dotsDiv = $(this).parent().find('.carousel-dots');
        var itemsPerRow = $(this).closest('.js-product-grid').data('grid-items-per-row') || {};
        var $carouselItem = $('.js-grid-item', this);
        var settings = {
          appendArrows: $arrowsDiv,
          infinite: true,
          slidesToShow: Math.min(itemsPerRow.landscape || 4, $carouselItem.length),
          slidesToScroll: Math.min(itemsPerRow.landscape || 4, $carouselItem.length),
          responsive: [
            {
              breakpoint: desktopWidth,
              settings: {
                slidesToShow: Math.min(itemsPerRow.portrait || 3, $carouselItem.length),
                slidesToScroll: Math.min(itemsPerRow.portrait || 3, $carouselItem.length)
              }
            },
            {
              breakpoint: ipadWidth,
              settings: {
                appendDots: dotsDiv,
                dots: true,
                slidesToShow: itemsPerRow.small || 1,
                slidesToScroll: itemsPerRow.small || 1
              }
            }
          ]
        };
        // slidesToShow override
        var slidesToShowCount = $(this).data('slides-to-show');

        if (slidesToShowCount) {
          settings.slidesToShow = slidesToShowCount;
        }
        // Init this carousel with our settings
        $(this).slick(settings);
        $(this).on('init', function () {
          $(document).trigger('product.launch.inlineCarousel');
        });
        // On before slide change
        $(this).on('beforeChange', function () {
          // Remove quickshop:
          if (hasQuickshop && Drupal.behaviors.quickshop) {
            $('.js-quickshop', $wrapper).remove();
          }
        });
      });

      var collectGridProductIds = function ($gridItems) {
        var gridItems = $.makeArray($gridItems);
        var allProductIds = $.map(gridItems, function (cell) {
          return $(cell).data('product-id');
        });

        return allProductIds;
      };

      function refreshCarousels() {
        $carousels.each(function () {
          var $carousel = $(this);
          if ($carousel.hasClass('slick-initialized')) {
            $carousel.slick('refresh');
          }
        });
      }

      $(document).on('inv_status_data:finished', function () {
        refreshCarousels();
      });

      var sortGrid = function (sortFn, type, args) {
        var allProductIds;
        var allProductData = [];
        var productType;
        var routerType;
        var $prodCell;
        var sortedProductData = [];
        var $grids = !!args && !!args.prodGrid ? $(args.prodGrid) : $('.js-product-grid', $wrapper);
        var touts = [];
        $grids.each(function () {
          var $grid = $(this);
          var $gridContent = $grid.hasClass('product-grid--carousel')
            ? $grid.find('.slick-track')
            : $grid.find('.js-product-grid-content');
          var sortedCells = [];
          $prodCell = $('.js-product-grid-item', $grid);
          allProductIds = collectGridProductIds($prodCell);
          _.each(allProductIds, function (prodId) {
            allProductData.push(prodcat.data.getProduct(prodId));
          });
          if (!!args && !!args.perfectMatchObj) {
            sortedProductData = sortFn(allProductData, args.perfectMatchObj);
          } else {
            sortedProductData = _.sortBy(allProductData, sortFn);
          }
          if (!!type) {
            sortedProductData.sort(function (first, second) {
              if (!first || !second) {
                return;
              }
              productType = second.PARENT_PRODUCT_TYPE.toLowerCase();
              routerType = decodeURIComponent(type).toLowerCase();
              if (productType !== routerType) {
                return -1;
              }
            });
          }
          _.each(sortedProductData, function (prod) {
            if (prod) {
              sortedCells.push(
                $('.js-product-grid-item[data-product-id=' + prod.PRODUCT_ID + ']', $grid)
              );
            }
          });
          touts = toutsPlaceholder.filter(function (tout) {
            return $grid.find(tout.$item).length > 0;
          });
          // apply original tout positions after sorting products
          _.each(touts, function (tout) {
            if (tout) {
              sortedCells.splice(tout.position, 0, tout.$item);
            }
          });

          _.each(sortedCells, function (cell) {
            $gridContent.append($(cell));
          });

          $grid.trigger('mixitup.initialize');
        });

        $(document).trigger('grid.reflow');
        $carousels.each(function () {
          $(this).slick('refresh');
        });
        if (hasQuickshop && prodcat.ui.quickshop) {
          $grids.each(function () {
            var $grid = $(this);
            prodcat.ui.quickshop.reset($grid);
            prodcat.ui.quickshop.init($grid);
          });
        }
      };

      $(document).on('product_grid.attach', function (e, $container) {
        self.attach($container);
      });

      $(document).on('mpp_sort:selected', function (e, sortFn, gridElement) {
        sortGrid(sortFn, null, { prodGrid: gridElement });
      });

      $(document).on('hairquiz.perfect_match', function (e, sortFn, hairQuizMatchObj) {
        var type = null;
        var $prdGridsMatchReordering = $('.js-hair-quiz-match-reordering', context);
        $prdGridsMatchReordering.each(function () {
          var $isCarousel = $(this).hasClass('product-grid--carousel');
          if (!$isCarousel) {
            var argSortParams = {
              prodGrid: this,
              perfectMatchObj: hairQuizMatchObj
            };

            sortGrid(sortFn, type, argSortParams);
          }
        });
      });

      $(document).on('recommended.gridLoaded', function (e, context) {
        if (context === undefined) {
          return;
        }
        var $shadeCarousels = $('.js-product-shade-picker__shades', context);
        var $multiShadeCarousels = $('.js-product-shade-picker__multi-shades', context);
        var $images = $('img[loading="lazy"]', context);

        $images.each(function () {
          var imgSrc = $(this).attr('data-src');
          $(this).removeClass('lazyload').attr('src', imgSrc).removeAttr('data-src');
        });

        $shadeCarousels.each(function () {
          var arrowsDiv = $(this).parent().find('.carousel-controls');
          var slides = $(this).parent().find('.js-shade-picker-carousel');
          var slidesToShow = is_mobile ? mobileSlideCountShaded : desktopSlideCountShaded;
          var settings = {
            appendArrows: arrowsDiv,
            infinite: false,
            slidesToShow: slidesToShow,
            slidesToScroll: slidesToShow
          };
          slides.not('.slick-initialized').slick(settings);
        });

        $multiShadeCarousels.each(function () {
          var arrowsDiv = $(this).parent().find('.carousel-controls');
          var slides = $(this).parent().find('.js-multi-shade-picker-carousel');
          var slidesToShow = is_mobile ? mobileSlideCountMultiShaded : desktopSlideCountMultiShaded;
          var settings = {
            appendArrows: arrowsDiv,
            infinite: false,
            slidesToShow: slidesToShow,
            slidesToScroll: slidesToShow
          };
          slides.not('.slick-initialized').slick(settings);
        });
      });

      // snapshot of initial tout position before sorting
      $grids.each(function () {
        var gridItems = $(this).find('.js-product-grid-item');
        gridItems.each(function (i) {
          if ($(this).find('.product-grid__tout').length > 0) {
            toutsPlaceholder.push({
              $item: $(this),
              position: i
            });
          }
        });

        // Trigger an event indicating our product grid has loaded
        // Used to format price fields - ex: remove '.00' from price
        $(document).trigger('product.gridLoaded', this);
        $(document).trigger('recommended.gridLoaded', this);
      });

      // sort by existing values in *PARENT_PRODUCT_TYPE* if specified via URL router: #/type/<type>
      if (!!prodcat.ui.productType) {
        var type = prodcat.ui.productType;

        $grids.each(function () {
          sortGrid('displayOrder', type, { prodGrid: this });
        });

        $(document).on('mpp_sort:product_type', function (e, type) {
          $grids.each(function () {
            sortGrid('displayOrder', type, { prodGrid: this });
          });
        });
      }
    }
  };
})(jQuery);
